import React from 'react'

const SiteIcon = function (props) {
    let { src, alt, width, height, className } = props
    let img_path = ''

    if (!src) return ''

    try {
        img_path = require('../../resources/img/logo-partner/favicons_' + src + '.png')
    } catch (e) {
        img_path = require('../../resources/img/u32.png')
    }

    return (
        <img
            src={img_path}
            width={width ? width : ''}
            height={height ? height : ''}
            className={className ? className : ''}
            alt={alt ? alt : ''}
        />
    )
}

export default SiteIcon
