import { Icon, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import cookies from 'react-cookies'
import CollapsedFormSearch from './CollapsedFormSearch'
import ExpandedFormSearch from './ExpandedFormSearch'

class FormSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: cookies.load('purchase_form_status') === 'true' ? true : false,
        }
    }

    handleCollapsed = e => {
        cookies.save('purchase_form_status', !this.state.collapsed, {
            path: '/',
            expires: moment().add(7, 'd').toDate(),
        })

        this.setState(preState => {
            return { ...preState, collapsed: !preState.collapsed }
        })
    }

    render() {
        return (
            <div className="list-order-form-search">
                {this.state.collapsed ? <CollapsedFormSearch {...this.props} /> : <ExpandedFormSearch {...this.props} />}
                <Row className={this.state.collapsed ? 'a-list--form-search py-1 text-center' : 'a-list--form-search py-1 text-center border-top'}>
                    <span
                        className="a-text--color-primary a--cursor-poiter a-text--fz-12"
                        onClick={this.handleCollapsed}>
                        {this.state.collapsed ? (
                            <>
                                <Icon type="arrows-alt" /> Bộ lọc mở rộng
                            </>
                        ) : (
                            <>
                                <Icon type="shrink" /> Bộ lọc thu gọn
                            </>
                        )}
                    </span>
                </Row>
            </div>
        )
    }
}

FormSearch.propTypes = {}

export default FormSearch
